<div class="row align-items-center">
    <div class="col-lg-3"></div>
    <div class="col-lg-4 col-12 col-sm-12 col-md-12">
        <div class="single-partner-item">
            <img src="assets/img/partner/neaform.jpg" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/logo-CNAAS-(1).jpg" alt="image">
        </div>
    </div>
</div>
