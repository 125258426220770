<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://maps.google.com/maps?q=Aeffe%20Consulting,%20Piazza%20Vittorio%20Veneto,%20Castel%20Baronia,%20AV%20Italia&t=k&z=19&ie=UTF8&iwloc=&output=embed"></iframe>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Chi siamo</span>
                    <h2>“Il motto è l’umiltà di prestare un servizio”</h2>
                    <p>
                        Aeffe Consulting nasce dall’incontro di diversi professionisti che vogliono creare un singolo punto di riferimento al cittadino e alle aziende. Al servizio del cittadino mettiamo a disposizione l’esperienza e la velocità dei caf e patronati LABOR, realtà dinamiche e di spessore.
                    </p>
                    <p>
                        Inoltre grazie a un network aziendale che contiene svariate collaborazioni mette al servizio del cittadino anche progetti per star up e finanza agevolata. Molta attenzione, inoltre vien data alla FORMAZIONE.

Alle aziende e agli imprenditori Aeffe Consulting si rivolge con servizi di finanza agevolata, formazione e sicurezza sul lavoro, bandi camera di commercio. Al fianco di Confimprenditori che da anni si batte per i diritti dei lavorati e degli imprenditori. Siamo una realtà completamente votata al cliente, in quanto quest’ultimo è considerato prima una persona e poi un target di riferimento.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">dalla formazione alla professione</span>
                    <h2>Cosa abbiamo di Speciale?</h2>
                    <p>
                        Aeffe Consulting è una società di consulenza per aziende, che opera nel settore della Salute e Sicurezza sul Lavoro, nasce con l’obiettivo di occuparsi della Formazione e della Consulenza progettuale in materia di Sicurezza sul Lavoro, HACCP, Qualità e Ambiente.
                    <p>
                        Aumenta il tuo punteggio per tutti i tuoi concorsi e fallo con noi, comodamente da casa tua, senza spostarti. Potrai seguire corsi online validi per l'inserimento nel mondo lavorativo e avrai una comoda piattaforma a te dedicata.
                        Le certificazioni ti aiutano a distinguerti nel tuo campo. Essendo i nostri corsi accreditati, siamo tenuti a rilasciare le certificazioni previste, necessarie a migliorare CV, credibilità e carriera.
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p>P.zza Vittorio Veneto,17<br>Castel Baronia (Av)</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Mobile: <a href="tel:+39082792499">(+39) 0827-92499</a></p>
                            <p>e-mail: <a href="mailto:info@aeffeconsulting.com">info@aeffeconsulting.com</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì - Venerdì: 09:00 - 18:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" ngModel id="sceltaCorso" #sceltaCorso="ngModel" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
